import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Footer.css'; // Assuming you have a separate CSS file for this component
import logo from '../assets/logo.png';

const Footer = ({ scrollToSection, servicesRef, productsRef,approachRef }) => {
  const navigate = useNavigate();

  const handleNavigation = (sectionRef, route) => {
    if (window.location.pathname !== '/') {
      navigate(route, { state: { scrollTo: sectionRef } });
    } else {
      scrollToSection(sectionRef);
    }
  };

  return (
    <footer className="footer-section">
      <div className="footer-content">
        <div className='footer-logo-section'>
        <div className="footer-logo">
            <img src={logo} alt="K.H.P.T Logo" />
            <h2 className='regular-text'>K.H.P.T</h2>
          {/* <div className="google-badge">
            <img src="path/to/google-badge.png" alt="Google PageSpeed Badge" />
          </div> */}
        </div>
        <p className='regular-text'>CONG TY TNHH K.H.P.T</p>
        </div>
        <div className="footer-links">
          <h3 className='bold-text'>K.H.P.T Links</h3>
          <ul>
            {/* <li><a href="#">About Us</a></li> */}
            {/* <li><a href="#services" onClick={() => handleNavigation(servicesRef, '/')}>Services</a></li> */}
            {/* <li><a href="#">Case Studies</a></li> */}
            {/* <li><a href="#products" onClick={() => handleNavigation(productsRef, '/')}>Products</a></li> */}
            {/* <li><a href="#perspective" onClick={() => handleNavigation(approachRef, '/')}>Our Perspective</a></li> */}
            <li><a href="/privacy-policy">Privacy policy</a></li>
          </ul>
        </div>
        <div className="footer-links">
          <h3 className='bold-text'>QuickFeed Links</h3>
          <ul>
            {/* <li><a href="#">About Us</a></li> */}
            {/* <li><a href="#services" onClick={() => handleNavigation(servicesRef, '/')}>Services</a></li> */}
            {/* <li><a href="#">Case Studies</a></li> */}
            {/* <li><a href="#products" onClick={() => handleNavigation(productsRef, '/')}>Products</a></li> */}
            {/* <li><a href="#perspective" onClick={() => handleNavigation(approachRef, '/')}>Our Perspective</a></li> */}
            <li><a href="/quickfeed/privacy-policy">Privacy policy</a></li>
            <li><a href="/quickfeed/community-standards">Community Standards</a></li>
            <li><a href="/quickfeed/terms-and-conditions-of-use">Terms & Conditions Of Use</a></li>
          </ul>
        </div>
        <div className="footer-contact">
          <h3>Contact us</h3>
          <p>CONG TY TNHH K.H.P.T</p>
          <p>+84 358081750</p>
          <p>support@khpt.com.vn</p>
        </div>
      </div>
      {/* <div className="footer-bottom">
        <p>© 2023 Copyright by IK Developers. All rights reserved.</p>
      </div> */}
    </footer>
  );
};

export default Footer;
